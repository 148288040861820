<template>
    <!-- view profile details -->
    <section class="app-user-view p-0">
        <!-- User Card & Plan Starts -->
        <div class="row ">
            <!-- User Card starts-->
            <base-card-plain
                :showHeader="false"
            >
             <template #default>
                 <div class="row">
                     <div class="col-xl-8 col-lg-12 d-flex flex-column justify-content-between border-container-lg">
                         <div class="user-avatar-section">
                             <div class="d-flex justify-content-start">
                                 <base-image
                                      imgName="7.png"
                                      imgGeo="avatars"
                                      imgAlt="User Avatar"
                                      imgClass="img-fluid rounded"
                                      :imgDim="[104,104]"
                                      >
                                  </base-image>
                                 <div class="d-flex flex-column ms-1">
                                     <div class="user-info mb-1">
                                         <h4 class="mb-0">Eleanor Aguilar</h4>
                                         <span class="card-text">eleanor.aguilar@gmail.com</span>
                                     </div>
                                     <div class="d-flex flex-wrap">
                                         <base-badge badgeColor="primary" >Software</base-badge>
                                         <base-badge badgeColor="primary" >Media Comms</base-badge>
                                         <base-badge badgeColor="primary" >Publicity</base-badge>
                                     </div>
                                 </div>
                             </div>
                         </div>

                     </div>
                     <div class="col-xl-4 col-lg-12 mt-2 mt-xl-0">
                         <div class="user-info-wrapper">
                             <div class="d-flex flex-wrap">
                                 <div class="user-info-title">
                                     <i data-feather="user" class="me-1"></i>
                                     <span class="card-text user-info-title fw-bold mb-0">Size: </span>
                                 </div>
                                 <p class="card-text mb-0 ps-1"> 1 - 10 staff</p>
                             </div>
                             <div class="d-flex flex-wrap my-50">
                                 <div class="user-info-title">
                                     <i data-feather="check" class="me-1"></i>
                                     <span class="card-text user-info-title fw-bold mb-0">Phone no: </span>
                                 </div>
                                 <p class="card-text mb-0 ps-1">08034443434</p>
                             </div>
                             <div class="d-flex flex-wrap my-50">
                                 <div class="user-info-title">
                                     <i data-feather="star" class="me-1"></i>
                                     <span class="card-text user-info-title fw-bold mb-0">Location: </span>
                                 </div>
                                 <p class="card-text mb-0 ps-1"> Accra, Ghana. </p>
                             </div>
                         </div>
                     </div>

                    <hr class="mt-1 mb-1" />
                    <div class="row">
                        <p>
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </p>

                        <h4 class="card-title mt-2">Address</h4>
                        <p class="card-text">
                            Some quick example text to build on the card title and make up the bulk of the card's content.
                        </p>

                        <h4 class="card-title mt-2">Social media</h4>
                        <p class="card-text">
                            <base-badge badgeColor="secondary" >twitter</base-badge>
                            <base-badge badgeColor="secondary" >facebook</base-badge>
                            <base-badge badgeColor="secondary" >Instagram</base-badge>
                        </p>
                    </div>
                 </div>
             </template>
            </base-card-plain>
            <!-- /User Card Ends-->

        </div>
        <!-- User Card & Plan Ends -->

        <!-- User Timeline & Permissions Starts -->
        <div class="row">
        </div>
        <!-- User Timeline & Permissions Ends -->

    </section>
    <!-- view profile details -->
</template>



<style scoped>
ul {
    list-style-type: none;
    padding:0px;
}

li {
    margin:15px 0px;
}

li i{
    margin-right:0.7rem;
}

.inline-small-spacing{
    margin-top:0px;
    margin-bottom:0.7rem;
}
</style>
