<template>
    <!-- form -->
    <form class="validate-form">
    <!-- filter questions -->
    <base-card-plain>
        <template #header>
            <h4 class="card-title">Update Personal Profile</h4>
        </template>
        <template #default>


            <div class="row">
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="account-birth-date">First name</label>
                        <input type="text" class="form-control " placeholder="First name" id="account-birth-date" name="dob" />
                    </div>
                </div>
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="account-birth-date">Last name</label>
                        <input type="text" class="form-control " placeholder="Last name" id="account-birth-date" name="dob" />
                    </div>
                </div>
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="basicSelect">Select gender </label>
                        <select class="form-select" id="basicSelect">
                            <option>Select</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="account-birth-date">Phone no</label>
                        <input type="text" class="form-control " placeholder="Phone no" id="account-birth-date" name="dob" />
                    </div>
                </div>
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="basicSelect"> Location country </label>
                        <select class="form-select" id="basicSelect">
                            <option>Select country</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="basicSelect"> Location city </label>
                        <select class="form-select" id="basicSelect">
                            <option>Select city</option>
                            <option>Male</option>
                            <option>Female</option>
                            <option>Other</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="account-birth-date">Address</label>
                        <input type="text" class="form-control " placeholder="Address" id="account-birth-date" name="dob" />
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-12 col-sm-12">
                    <div class="mb-1">
                        <label class="form-label" for="basicSelect">Select role </label>
                        <select class="form-select" id="basicSelect">
                            <option>Select</option>
                            <option>Admin</option>
                            <option>HR assistant</option>
                            <option>Subject matter specialist</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="col-12">
                <div class="mb-1">
                    <label class="form-label" for="accountTextarea">Person overview</label>
                    <textarea class="form-control" id="accountTextarea" rows="4" placeholder="Your Bio data here..."></textarea>
                </div>
            </div>


            <div class="col-12">
                <base-button btnColor="primary" >Submit request for approval</base-button>
            </div>



        </template>
    </base-card-plain>
    <!-- / filter questions -->






    </form>
    <!--/ form -->
</template>

<script>
// import components
import BaseCardPlain from '@/components/ui/BaseCardPlain.vue';
import BaseButton from '@/components/ui/BaseButton.vue';

export default{
    props:{
        useExistingJD:{
            default:false,
            type: Boolean,
            required: false
        }
    },
    components:{
        BaseCardPlain,
        BaseButton
    }
}


</script>
